import React from 'react'
import Hero from '../Hero/Hero'
import './Page.css'



const Home = () => {


  return (

    <>

      <Hero />
    
    </>
  )
}

export default Home